.content-container {
    position: relative;
    top: 0;
    margin: 0;
    min-height: 100%;
    height: auto;
    max-width: 100%;
    padding: 30px 15px 15px 15px;
    &.with-sidebar {
        padding: 30px 15px 15px 0;
        border-left: none;
    }
}

.content {
    padding: 15px;
    .with-sidebar & {
        padding: 15px 0 15px 15px;
    }
}


.table > tbody > tr > td {
    line-height: 1.6;
    .prefix {
        display: inline-block;
        min-width: 60px;
    }
}

@media (min-width: $screen-sm-min) {

    .content-container {
        padding: 30px 15px 20px 15px;
        &.with-sidebar {
            padding: 30px 15px 20px 0;
        }
    }

    .content {
        padding: 30px 15px 30px 15px;
        .with-sidebar & {
            padding: 30px 0 30px 15px;
        }
    }

}

@media (min-width: $screen-md-min) {

    .content-container {
        padding: 45px 30px 20px 30px;
        &.with-sidebar {
            padding: 45px 30px 20px 0;
            border-left: $gray-darker 200px solid;
        }
    }

    .content {
        padding: 30px 30px 30px 30px;
        .with-sidebar & {
            padding: 30px 0 30px 30px;
        }
    }

}