html {
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    background-color: #f0f0f0;
    padding: 0;
    margin: 0;
    height: 100vh;
}

[data-toggle="call"] {
    white-space: nowrap;
}

.dropdown-menu {
    font-size: 13px;
}

.fa-font {
    margin-right: 6px;
}

.tooltip-inner {
    padding: 5px 10px;
    border-radius: 0;
}


hr.divider {
    padding: 0;
    border: none;
    border-top: 1px solid $table-border-color;
    color: #555;
    text-align: center;
    margin: 75px 0 65px;
}

hr.divider:after {
    content: attr(title);
    display: inline-block;
    position: relative;
    top: -13px;
    font-size: 16px;
    padding: 0 20px;
    background: #fff;
}