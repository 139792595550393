// Auth Styles

.home {
    .login-content {
        max-width: 350px;
        margin: 90px auto 0;
    }

    .navbar {
        background-color: $gray-dark;
        .navbar-brand {
            background-color: transparent;
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }

    input {
        background-color: #fbfbfb;
        border-color: #e4e4e4;
    }

}