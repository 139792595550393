.admin-bar {
    .banner,
    .sidebar .sidebar-container  {
        margin-top: 46px;
        @media (min-width: 783px) {
            margin-top: 32px;
        }
    }
    #wpadminbar {
        &, .menupop {
            z-index: 1030 !important;
        }
    }
}

.navbar {
    border: none;
    background-color: $gray-dark;
}

.navbar-container {
    padding-left: 0;
    padding-right: 0;
}

.navbar-default {
    .navbar-brand {
        background-color: $gray-dark;
        width: 200px;
        padding: 11px 15px 9px;
        &:hover,
        &:focus {
            background-color: darken($gray-darker, 3%);
        }
    }
    .navbar-collapse,
    .navbar-toggle {
        background-color: #fff;
        span.icon-bar {
            background-color: $text-color;
        }
    }
    .navbar-form {
        input, button {
            margin: 0;
            display: inline-block;
            vertical-align: middle;
        }
    }

}

.nav.navbar-nav > .dropdown:hover {
    > a,
    & {
        background-color: $gray-darker;
        color: #f0f0f0;
    }
    .dropdown-menu {
        display: block;
    }
}

.logoff {
    background-color: $brand-primary;
    color: #fff;
    a, a i, span { color: #fff; }
}

@media (min-width: $screen-md-min) {

    .navbar {
        background-color: #fff;
    }

    .navbar-container {
        padding-right: 45px;
    }

    .navbar-default {
        .navbar-form {
            float: left;
            padding-left: 25px;
        }
    }

}