// Alerts
// -------------------------
@mixin alert-variant($color) {
  background-color: #fff;
  border-left-color: $color;
  color: inherit;

  hr {
    //border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: inherit;
  }
}

.alert {
  padding: $alert-padding;
  margin-bottom: $line-height-computed;
  border: 0;
  border-left: 4px solid;
  border-radius: 0;
  font-size: 13px;
  box-shadow: 0 0 0 1px #e4e4e4, 0 1px 1px 1px #eaeaea;

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing $headings-color
    color: inherit;
  }
  // Provide class for links that match alerts
  .alert-link {
    font-weight: $alert-link-font-weight;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }
  > p + p {
    margin-top: 5px;
  }
}

.alert-success {
  @include alert-variant($brand-success);
}
.alert-info {
  @include alert-variant($brand-info);
}
.alert-warning {
  @include alert-variant($brand-warning);
}
.alert-danger {
  @include alert-variant($brand-danger);
}
