// Search form
.search-form {
    @extend .form-inline;
}
.search-form label {
    font-weight: normal;
    @extend .form-group;
}
.search-form .search-field {
    @extend .form-control;
}
.search-form .search-submit {
    @extend .btn;
    @extend .btn-default;
}



label {
    font-weight: normal;
}

.twitter-typeahead {

    .tt-input[style] {
        vertical-align: middle !important;
    }

    .tt-menu,
    .tt-dropdown-menu {
        @extend .dropdown-menu;
    }

    .tt-suggestion {
        display: block;
        padding: 3px 15px;
        clear: both;
        font-weight: normal;
        line-height: $line-height-base;
        color: $dropdown-link-color;
        white-space: nowrap; // prevent links from randomly breaking onto new lines
        font-size: 14px;
        text-decoration: none;
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
        &.tt-cursor,
        &:hover,
        &:focus {
            color: $dropdown-link-active-color;
            text-decoration: none;
            outline: 0;
            background-color: $dropdown-link-active-bg;
            cursor: pointer;
        }
    }

    .input-group & {
        display: block !important;
        height: $input-height-base;
        .tt-menu,
        .tt-dropdown-menu {
            top: 32px !important;
        }
        &:not(:first-child):not(:last-child) {
            .form-control {
                border-radius: 0;
            }
        }
        &:first-child {
            .form-control {
                border-top-left-radius: $input-border-radius;
                border-bottom-left-radius: $input-border-radius;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        &:last-child {
            .form-control {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: $input-border-radius;
                border-bottom-right-radius: $input-border-radius;
            }
        }
    }

}