.box {
    background-color: #fff;
    padding: 15px;
    margin: 0 -15px 30px;
    box-shadow: 0 0 0 1px #e4e4e4, 0 1px 1px 1px #eaeaea;
    //@include clearfix();
}

@media (min-width: $screen-sm-min) {

    .box {
        margin: 0 0 30px;
    }

}

@media (min-width: $screen-md-min) {

    .box {
        padding: 25px;
    }

}