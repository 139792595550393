.sidebar {
    display: none;
}


@media (min-width: $screen-md-min) {

    .sidebar {
        display: block;
        float: left;
        width: 200px;
        color: #fff;
        background-color: $gray-darker;
        min-height: 650px;
        left: 0;

        .fa-inverse {
            color: $gray-dark;
        }

        .sidebar-container {
            z-index: 900;
            padding-top: 50px;
            position: absolute;
            width: 200px;
            color: #f0f0f0;
            left: 0;
            top: 0;
            background-color: $gray-darker;
        }

        .sidebar-user {
            padding: 20px;
            text-align: center;
            img {
                border-radius: 100%;
                border: 5px solid $gray-dark;
            }
        }

        .nav {
            > li {
                i {
                    margin-right: 6px;
                }
                > a {
                    &:hover,
                    &:focus {
                        background-color: darken($gray-darker, 3%);
                    }
                }
            }
        }


        // Pills
        // -------------------------
        .nav-pills {
            > li {
                box-shadow: 0 0 1px rgba(0,0,0,0); //Anti Font Flicker Hack

                > a {
                    border-radius: 0;
                    color: #e0e0e0;
                }

                &.active > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $nav-pills-active-link-hover-color;
                        background-color: darken($gray-darker, 3%);
                        border-left: 4px solid $nav-pills-active-link-hover-bg;
                        padding-left: 11px;
                    }
                }

                ul {
                    display: none;
                }

                &.active > ul,
                &.active:hover > ul {
                    display: block;
                    position: relative;
                    top: auto;
                    right: auto;
                    width: auto;
                    background-color: darken($gray-dark, 2%);
                    list-style-type: none;
                    padding: 10px 0;
                    font-size: 13px;
                    box-shadow: none;
                    &:hover .nav-arrow,
                    .nav-arrow {
                        position: absolute;
                        margin: 0;
                        right: 12px;
                        top: -8px;
                        display: block;
                        width: 0;
                        height: 0;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 8px solid darken($gray-dark, 2%);
                        border-top: 0;
                    }
                    li {
                        margin-bottom: 0;
                        a {
                            color: #a0a0a0;
                            padding: 5px 20px;
                            display: block;
                            &:hover {
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                        &.active > a {
                            &,
                            &:hover,
                            &:focus {
                                color: #e0e0e0;
                                font-weight: 600;
                            }
                        }
                    }
                }

                &:hover > ul {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: -175px;
                    width: 175px;
                    background-color: darken($gray-dark, 2%);
                    list-style-type: none;
                    padding: 10px 0;
                    font-size: 13px;
                    z-index: $zindex-dropdown;
                    box-shadow: 0 0 3px darken($gray-darker, 3%);
                    .nav-arrow {
                        position: absolute;
                        margin-top: 2px;
                        margin-left: -8px;
                        display: block;
                        width: 0;
                        height: 0;
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                        border-right: 8px solid darken($gray-dark, 2%);
                    }
                    li {
                        margin-bottom: 0;
                        a {
                            color: #a0a0a0;
                            padding: 5px 20px;
                            display: block;
                            &:hover {
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                        &.active > a {
                            &,
                            &:hover,
                            &:focus {
                                color: #e0e0e0;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

        .nav-stacked {
            > li {
                + li {
                    margin-top: 5px;
                }
            }
        }

    }

}

@media (min-height: 650px) {

    .sidebar {
        .sidebar-container {
            position: fixed;
        }
    }

}