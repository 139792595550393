footer {
    display: none;
}


@media (min-width: $screen-md-min) {

    footer {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px 30px;
        font-size: 13px;
        color: $gray-medium;
        .copyright {
            font-style: italic;
        }
    }

}